import { Component, OnInit } from '@angular/core';
import { ContactForm } from '../../../Interfaces/contact-form';
import { ContactService } from '../../../Services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  formulaireContact: ContactForm = {} as ContactForm;
  formulaireValid: boolean = false;
  mailSendSuccess: boolean = false;
  mailSendError: boolean = false;
  contactService: ContactService;
  constructor(newContactService: ContactService) {
    this.contactService = newContactService;
  }

  sendContactMail(): void {
    if (this.formulaireValid) {
      this.contactService.sendContactMail(this.formulaireContact).subscribe({
        next: (success) => {
          if (success) {
            this.mailSendSuccess = true;
            this.formulaireContact = {} as ContactForm;
            const timer = setTimeout(() => {
              this.clearSendMailBool(true);
            }, 10000);
          }
          else {
            this.mailSendError = true;
            const timer = setTimeout(() => {
              this.clearSendMailBool(false);
            }, 10000);
          }
        },
        error: (err) => {
          this.mailSendError = true;
          const timer = setTimeout(() => {
            this.clearSendMailBool(false);
          }, 10000);
        }
      });
    }
  }

  checkValidityForm(): void {
    let valideName: boolean = false;
    let valideMail: boolean = false;
    let valideSujet: boolean = false;
    let valideMessage: boolean = false;
    if (this.formulaireContact.nom !== undefined && this.formulaireContact.nom.replace(/\s+/g, '') !== '') {
      valideName = true;
    }
    if (this.formulaireContact.mail !== undefined && this.formulaireContact.mail.replace(/\s+/g, '') !== '') {
      valideMail = true;
    }
    if (this.formulaireContact.sujet !== undefined && this.formulaireContact.sujet.replace(/\s+/g, '') !== '') {
      valideSujet = true;
    }
    if (this.formulaireContact.message !== undefined && this.formulaireContact.message.replace(/\s+/g, '') !== '') {
      valideMessage = true;
    }
    this.formulaireValid = valideName && valideMail && valideSujet && valideMessage;
  }

  clearSendMailBool(success: boolean) {
    if (success) {
      this.mailSendSuccess = false;
    }
    else {
      this.mailSendError = false;
    }
  }

  ngOnInit(): void {}
}
