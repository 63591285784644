import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {Project} from '../Interfaces/project';
import {Stat} from '../Interfaces/stat';

@Injectable({
  providedIn: 'root'
})
export class StatService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllStats(): Observable<Stat[]> {
    return new Observable((observer: Subscriber<Stat[]>) => {
      this.http.get<Stat[]>(this.urlApi + '/stat/get-all')
        .subscribe((ResultStats: Stat[]) =>
          observer.next(ResultStats));
    });
  }
}
