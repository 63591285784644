<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Compétences</h2>

  <div class="spacer-60"></div>

  <div class="row">

    @for (skill of primarySkills; track skill) {
      <div class="col-md-6">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light" [ngClass]="'bg-' + skill.color_bg + ' shadow-' + skill.color_bg">
          <img [src]="skill.image" *ngIf="skill.size === undefined" [alt]="skill.title"/>
          <img [src]="skill.image" *ngIf="skill.size !== undefined" [alt]="skill.title" [style.width.%]="skill.size" />
          <h3 class="mb-3 mt-0" [ngClass]="skill.color_text !== undefined ? 'text-' + skill.color_text : ''">
            {{ skill.title }}
          </h3>
          <p class="mb-0" [innerHTML]="skill.description" [ngClass]="skill.color_text !== undefined ? 'text-' + skill.color_text : ''" ></p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>
    }
    @empty {
      <div class="col-md-6">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light bg-blue shadow-blue">
          <img src="./assets/images/service-2.svg" alt="titleCompetence" width="" />
          <h3 class="mb-3 mt-0 text-color" >
            Développement Web BackEnd
          </h3>
          <p class="mb-0 text-color">
            Je fais du développement web backend avec <strong>Symfony</strong>, <strong>Ruby on Rails</strong> et <strong>Laravel</strong>.
          </p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>

      <div class="spacer-30 d-md-none d-lg-none"></div>

      <div class="col-md-6">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light bg-yellow shadow-yellow">
          <img src="./assets/images/service-1.svg" alt="titleCompetence" width="" />
          <h3 class="mb-3 mt-0 text-secondary" >
            Développement Web FrontEnd
          </h3>
          <p class="mb-0 text-secondary">
            Je fais du développement web frontend avec <strong>Angular</strong>, <strong>Vue</strong> en <strong>TypeScript</strong>.
          </p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>
    }

  </div>

  <br>

  <div class="row">

    @for (skill of otherSkills; track skill) {
      <div class="col-md-4">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light" [ngClass]="'bg-' + skill.color_bg + ' shadow-' + skill.color_bg">
          <img [src]="skill.image" *ngIf="skill.size === undefined" [alt]="skill.title"/>
          <img [src]="skill.image" *ngIf="skill.size !== undefined" [alt]="skill.title" [style.width.%]="skill.size" />
          <h3 class="mb-3 mt-0" [ngClass]="skill.color_text !== undefined ? 'text-' + skill.color_text : ''">
            {{ skill.title }}
          </h3>
          <p class="mb-0" [innerHTML]="skill.description" [ngClass]="skill.color_text !== undefined ? 'text-' + skill.color_text : ''" ></p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>
    }
    @empty {
      <div class="col-md-4">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light bg-pink shadow-pink">
          <img src="./assets/images/price-3.svg" alt="" />
          <h3 class="mb-3 mt-0 text-color">
            Développement Logiciel
          </h3>
          <p class="mb-0 text-color">
            Je fais très peu de développement logiciel, quand j'ai besoin de faire du bas niveau, j'utilise <strong>Go</strong> ou <strong>Rust</strong>.
          </p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>

      <div class="col-md-4">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light bg-green shadow-green">
          <img src="./assets/images/service-4.png" alt="" width="37%" />
          <h3 class="mb-3 mt-0 text-color">
            Linux & Réseau
          </h3>
          <p class="mb-0 text-color">
            J'utilise Linux depuis plus de 5ans. <strong>Arch</strong>/<strong>OpenSuse</strong> en personnel et <strong>debian</strong>/<strong>RHEL</strong> sur mes serveurs.
          </p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>

      <div class="col-md-4">
        <!-- service box -->
        <div class="service-box rounded data-background padding-30 text-center text-light bg-bordeau shadow-bordeau">
          <img src="./assets/images/pipelineGoodSize.png" alt="" width="32%" />
          <h3 class="mb-3 mt-0 text-color">
            Pipelines CI/CD
          </h3>
          <p class="mb-0 text-color">
            J'utilise <strong>GitlabCI</strong> et <strong>DroneCI</strong> pour déployer mes applications avec <strong>Ansible</strong> et <strong>Docker</strong>.
          </p>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>
    }

  </div>

  <!--
  <div class="mt-5 text-center">
    <p class="mb-0">Looking for a custom job? <a href="javascript:" (click)="scrollTo('contact')">Click here</a> to contact me! 👋</p>
  </div>
  -->
</div>
