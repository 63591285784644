import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { School } from '../Interfaces/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllSchools(): Observable<School[]> {
    return new Observable((observer: Subscriber<School[]>) => {
      this.http.get<School[]>(this.urlApi + '/school/get-all')
        .subscribe((ResultSchools: School[]) =>
          observer.next(ResultSchools));
    });
  }
}
