<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Latest Posts</h2>

  <div class="spacer-60"></div>

  <div class="row blog-wrapper">
    <div class="col-md-4">
      <!-- blog item -->
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Reviews</span>
          </a>
          <a href="javascript:" (click)="scrollTo('home')">
            <img src="assets/images/blog/1.svg" alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title"><a href="javascript:">5 Best App Development Tool for Your Project</a></h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">09 February, 2020</li>
            <li class="list-inline-item">Bolby</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <!-- blog item -->
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Tutorial</span>
          </a>
          <a href="javascript:" (click)="scrollTo('home')">
            <img src="assets/images/blog/2.svg" alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title"><a href="javascript:">Common Misconceptions About Payment</a></h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">07 February, 2020</li>
            <li class="list-inline-item">Bolby</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <!-- blog item -->
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Business</span>
          </a>
          <a href="javascript:" (click)="scrollTo('home')">
            <img src="assets/images/blog/3.svg" alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title"><a href="javascript:">3 Things To Know About Startup Business</a></h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">06 February, 2020</li>
            <li class="list-inline-item">Bolby</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
