import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {Stat} from '../Interfaces/stat';
import {Skill} from '../Interfaces/skill';

@Injectable({
  providedIn: 'root'
})
export class CompetencesService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllSkills(): Observable<Skill[]> {
    return new Observable((observer: Subscriber<Skill[]>) => {
      this.http.get<Skill[]>(this.urlApi + '/skill/get-all')
        .subscribe((ResultSkills: Skill[]) =>
          observer.next(ResultSkills));
    });
  }
}
