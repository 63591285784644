<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Mes Projets</h2>

  @if(projects.length > 0) {

    <div class="spacer-60"></div>

    <!-- portfolio filter (desktop) -->

    <ul class="portfolio-filter list-inline wow fadeInUp">
      <li class="list-inline-item" [ngClass]="{ current: categoryFilter === 0 }"
          (click)="categoryFilter = 0">Tous</li>
      <li class="list-inline-item" *ngFor="let category of categories"
          [ngClass]="{ current: categoryFilter === category.id }"
          (click)="categoryFilter = category.id">{{ category.title }}</li>
    </ul>

    <!-- portfolio filter (mobile) -->
    <div class="pf-filter-wrapper">
      <select class="portfolio-filter-mobile" (change)="onChange($event)">
        <option value="0">Tous</option>
        <option *ngFor="let category of categories" [value]="category.id">{{ category.title }}</option>
      </select>
    </div>

    <!-- portolio wrapper -->
    <div class="row portfolio-wrapper">

      <!-- portfolio item -->

      <div class="col-md-4 col-sm-6 grid-item creative design"
           *ngFor="let project of projects"
           [hidden]="categoryFilter !== project.category_id && categoryFilter !== 0"
      >
        <a href="javascript:" class="work-content" data-toggle="modal" (click)="exampleModalCenter.show()">
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">{{ getCategTitle(project.category_id) }}</span>
              <h4 class="title">{{ project.title }}</h4>
              <span class="more-button"><i class="icon-options"></i></span>
            </div>
            <div class="thumb">
              <img [src]="project.image" alt="Portfolio-title"/>
              <div class="mask"></div>
            </div>
          </div>
        </a>

        <app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
          <div class="app-modal-body">
            <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
              <img [src]="project.image" alt="Title"/>
              <h2>{{ project.title }}</h2>
              <p>
                {{ project.description }}
              </p>
              <a *ngIf="project.link" [href]="project.link" target="_blank" class="btn btn-default d-inline">En savoir plus</a>
              <h3 class="d-inline" style="margin-left: 5px">
                <span *ngIf="project.prod" style="color: white" class="badge bg-success float-right d-inline">En Production</span>
                <span *ngIf="!project.prod" style="color: white" class="badge bg-danger float-right d-inline">En développement</span>
              </h3>
            </div>
          </div>
        </app-ui-modal>
      </div>
    </div>

  } @else {
    <div class="spacer-30"></div>

    <div class="alert alert-danger" title="Le site n'arrive pas à joindre l'api pour récupérer les utilisateurs." role="alert">
      Malheureusement, je n'arrive pas à joindre l'api pour récupérer les projets !
    </div>
  }
</div>
