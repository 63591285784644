import { Component, OnInit, Input } from '@angular/core';
import { Work } from '../../../Interfaces/work';
import { School } from '../../../Interfaces/school';
import { WorkService } from '../../../Services/work.service';
import { SchoolService } from '../../../Services/school.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  allWorks: Work[] = [];
  allSchool: School[] = [];

  constructor(workService: WorkService, schoolService: SchoolService) {
    workService.getAllWorks().subscribe({
      next: (resultWorks) => {
        this.allWorks = resultWorks;
        this.allWorks.sort((a, b) => a.order - b.order);
      }
    });
    schoolService.getAllSchools().subscribe({
      next: (resultSchools) => {
        this.allSchool = resultSchools;
        this.allSchool.sort((a, b) => a.order - b.order);
      }
    })
  }

  ngOnInit(): void {}
}
