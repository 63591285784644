import { Component, OnInit } from '@angular/core';
import {Project} from '../../../Interfaces/project';
import {ProjetService} from '../../../Services/projet.service';
import {CategoryService} from '../../../Services/category.service';
import {Category} from '../../../Interfaces/category';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  public projects: Project[] = [];
  public categories: Category[] = [];
  public categoryFilter: number;

  constructor(projectService: ProjetService, categoryService: CategoryService) {
    categoryService.getAllCategory().subscribe((catgoryRes: Category[]) => {
      this.categories = catgoryRes;
    });
    projectService.getAllProjects().subscribe((projectsRes: Project[]) => {
      this.projects = projectsRes;
      this.projects.sort((a, b) => (a.id > b.id) ? -1 : 1);
    });
    this.categoryFilter = 0;
  }

  getCategTitle(idCateg: number): string {
    const currentCateg = this.categories.find(categSearch => categSearch.id === idCateg);
    return currentCateg.title;
  }

  ngOnInit(): void {}

  onChange(e): void {
    this.categoryFilter = e.target.value;
  }
}
