import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ContactForm } from '../Interfaces/contact-form';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public sendContactMail(contactForm: ContactForm): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      this.http.post(this.urlApi + '/contact_send', contactForm)
        .subscribe({
          next: (res) => observer.next(true),
          error: (err) => observer.next(false)
        });
    });
  }

}
