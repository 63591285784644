<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Présentation</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/avatar-custom.png" width="83%" alt="Bolby" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col">
            <!-- about text -->
            <p>
              J'ai {{age}} ans, je suis actuellement en licence CAISI au lycée Pasteur Mont Roland à Dole (39100). Je suis
              très au fait de l'actualité que ce soit au sujet des nouvelles technologies ou de sujets divers. En plus
              de ma formation, j'apprends énormément en autodidacte, je suis assidu et aime le travail en équipe.
            </p>
            <!--
            <div class="mt-3">
              <a href="javascript:" class="btn btn-default">Télécharge mon CV !</a>
            </div> -->
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if(stats.length != 0) {
    <!-- row end -->
    <div class="spacer-70"></div>


    <div class="row">

      @for (stat of stats; track stat) {
        <div class="col-md-3 col-sm-6">
          <!-- fact item -->
          <div class="fact-item">
            <span class="icon" [ngClass]="stat.icon"></span>
            <div class="details">
              <h3 class="mb-0 mt-0 number"><em class="count">{{stat.value}}</em></h3>
              <p class="mb-0">{{stat.title}}</p>
            </div>
          </div>
          <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>
      }

    </div>
  }


</div>
