import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {Project} from '../Interfaces/project';

@Injectable({
  providedIn: 'root'
})
export class ProjetService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllProjects(): Observable<Project[]> {
    return new Observable((observer: Subscriber<Project[]>) => {
      this.http.get<Project[]>(this.urlApi + "/project/get-all")
        .subscribe((ResultProjct: Project[]) =>
          observer.next(ResultProjct));
    });
  }

}
