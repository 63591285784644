
import { Component, OnInit } from '@angular/core';
import {CompetencesService} from '../../../Services/competences.service';
import {Skill} from '../../../Interfaces/skill';

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit {
  competenceService: CompetencesService;
  primarySkills: Skill[] = [];
  otherSkills: Skill[] = [];
  constructor(competenceServiceInject: CompetencesService) {
    this.competenceService = competenceServiceInject;
    this.getSkills();
  }

  ngOnInit(): void {}

  getSkills(): void {
    this.competenceService.getAllSkills().subscribe({
      next: (resultSkill: Skill[]) => {
        resultSkill.forEach(elem => {
          if (elem.order <= 2) {
            this.primarySkills.push(elem);
          }
          else {
            this.otherSkills.push(elem);
          }
        });
        this.primarySkills.sort((a, b) => a.order - b.order);
        this.otherSkills.sort((a, b) => a.order - b.order);
      }
    });
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
