import { Component, OnInit } from '@angular/core';
import {Stat} from '../../../Interfaces/stat';
import {StatService} from '../../../Services/stat.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  statService: StatService;
  age: number;
  stats: Stat[] = [];

  constructor(statServiceInject: StatService) {
    this.statService = statServiceInject;
    this.getAge();
    this.getStats();
  }

  getAge(): void {
    const dateNow: Date = new Date();
    const naissance: Date = new Date(2002, 10, 13);
    this.age = dateNow.getFullYear() - naissance.getFullYear();
    // Novembre = 11 - 1, car commence à 0.
    if (dateNow.getMonth() === 10) {
      if (dateNow.getDate() < 13) {
        this.age--;
      }
    }
    else if (dateNow.getMonth() < 10) {
      this.age--;
    }
  }

  getStats(): void {
    this.statService.getAllStats().subscribe({
      next: (resultStats: Stat[]) => {
        this.stats = resultStats;
      }
    });
  }

  ngOnInit(): void {}
}
