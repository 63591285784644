<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Expérience</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->

        @for(school of allSchool; track school; let indexSchool = $index) {
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">{{school.moment}}</span>
              <h3 class="title">{{school.title}}</h3>
              <p>
                {{school.description}}
                @if (school.note !== null) {
                  - <strong>{{ school.note}}</strong>
                }
              </p>
            </div>
          </div>
        } @empty {

          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2023 - 2024</span>
              <h3 class="title">CAISI (CNAM)</h3>
              <p>
                Je suis actuellement en deuxième année du titre CAISI (Concepteur en Architecture Informatique parcours Systèmes d’Information - Cnam) au lycée Pasteur Mont Roland.
              </p>
            </div>
          </div>


          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2022 - 2023</span>
              <h3 class="title">Licence STSIG</h3>
              <p>
                Licence Sciences technologies santé mention informatique parcours Informatique générale obtenue à la fin de ma première année du titre CAISI. - <strong>16.08/20</strong>
              </p>
            </div>
          </div>


          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2021 - 2022</span>
              <h3 class="title">BTS SIO</h3>
              <p>
                BTS SIO (Services informatiques aux organisations) option SLAM (solutions logicielles et applications métiers) - <strong>15.2/20</strong>
              </p>
            </div>
          </div>


          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2019 - 2020</span>
              <h3 class="title">Bac Professionnel SN</h3>
              <p>
                Bac Pro Systèmes numériques option Réseaux Informatiques et Systèmes Communicants - <strong>Mention Très Bien</strong>
              </p>
            </div>
          </div>


          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2016 - 2017</span>
              <h3 class="title">Diplome National du Brevet</h3>
              <p>
                Brevet des collèges - <strong>Mention Bien</strong>
              </p>
            </div>
          </div>

        }

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">
        <!-- timeline item -->

        @for(work of allWorks; track work; let indexWork = $index) {
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">{{work.moment}}</span>
              <h3 class="title">{{ work.title }}</h3>
              <p><strong>{{ work.poste }}</strong></p>
              <p>
                {{ work.description }}
              </p>
            </div>
          </div>
        } @empty {
          <div class="timeline-container wow fadeInUp">
            <div class="content">
              <span class="time">2022 - Present</span>
              <h3 class="title">Lycée Pasteur Mont Roland</h3>
              <p><strong>Développeur Web Fullstack - Alternance</strong></p>
              <p>
                Je développe et maintien des applications web pour le lycée, nous nous occupons également de l'assistance
                utilisateur.
              </p>
            </div>
          </div>

          <!-- timeline item -->
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
            <div class="content">
              <span class="time">2022 - Present</span>
              <h3 class="title">Freelance</h3>
              <p><strong>Cours particuliers de programmation</strong></p>
              <p>
                Sur mon temps libre, je donne des cours sur les bases de la programmation à des débutants et des cours
                avancée sur Laravel aux développeurs PHP.
              </p>
            </div>
          </div>

          <div class="timeline-container wow fadeInUp">
            <div class="content">
              <span class="time">Janvier 2022 - Février 2022</span>
              <h3 class="title">Lycée Pasteur Mont Roland</h3>
              <p><strong>Développeur Web Laravel - Stage</strong></p>
              <p>
                Durant ce stage, j'ai développé une application web de gestion en Laravel.
              </p>
            </div>
          </div>

          <!-- timeline item -->
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
            <div class="content">
              <span class="time">Mai 2021 - Juillet 2021</span>
              <h3 class="title">Bel - Fromagerie de Dole</h3>
              <p><strong>Service Performance Industriel - Stage puis Intérime</strong></p>
              <p>
                Durant ce stage, j'ai réalisé un recensement de toutes les bases de données de l'usine comportant des
                indicateurs.
              </p>
            </div>
          </div>

          <!-- timeline item -->
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
            <div class="content">
              <span class="time">November 2019 - Décembre 2019</span>
              <h3 class="title">Ministère des Armées - PFAT Denain</h3>
              <p><strong>Développement Java & Assistance utilisateur - Stage</strong></p>
              <p>
                Durant ce stage de terminal, j'ai développé un module Java pour une application existante.
                J'ai également fait de l'assistance utilisateur.
              </p>
            </div>
          </div>

          <!-- timeline item -->
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
            <div class="content">
              <span class="time">Avril 2019 - Mai 2019</span>
              <h3 class="title">Gendarmerie National</h3>
              <p><strong>Unité SIC - Stage</strong></p>
              <p>
                Durant ce stage, j'ai déployé des gendbuntu sur des ordinateurs, nous avons également tiré des lignes
                rj-45 et fait des repérages pour une future installation réseau.
              </p>
            </div>
          </div>

          <!-- timeline item -->
          <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
            <div class="content">
              <span class="time">Janvier 2019 - Février 2019</span>
              <h3 class="title">Magasin Danny's Micro Dijon</h3>
              <p><strong>Réparation d'ordinateur - Stage</strong></p>
              <p>
                Durant ce stage, j'ai pu monter, installer des ordinateurs. Supprimer tous les virus et malwares d'ordinateurs
                et effectuer des ventes de matériels informatiques.
              </p>
            </div>
          </div>
        }

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
