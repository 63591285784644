import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {Project} from '../Interfaces/project';
import {Category} from '../Interfaces/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllCategory(): Observable<Category[]> {
    return new Observable((observer: Subscriber<Category[]>) => {
      this.http.get<Category[]>(this.urlApi + '/category/get-all')
        .subscribe((ResultCategory: Category[]) =>
          observer.next(ResultCategory));
    });
  }
}
