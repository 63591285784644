<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Mes Outils</h2>

  <div class="spacer-60"></div>

  <!--
  <div class="testimonials-wrapper">
    <ngb-carousel *ngIf="images" [interval]="5000" [showNavigationArrows]="false">
      <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/avatar-3.svg" alt="customer-name" />
          </div>
          <h4 class="mt-3 mb-0">John Doe</h4>
          <span class="subtitle">Product designer at Dribbble</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/avatar-3.svg" alt="customer-name" />
          </div>
          <h4 class="mt-3 mb-0">John Doe</h4>
          <span class="subtitle">Product designer at Dribbble</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
            </p>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  -->

  <div class="row">
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise gitkraken pour la gestion de mes projets git !">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/gitkraken.png' : 'assets/images/tools/gitkraken.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise les IDE jetbrains pour développer mes projets">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/jetbrains.png' : 'assets/images/tools/jetbrains.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise Visual Studio Code pour coder mes scripts bash et effectuer de petites modifications rapides sur mes projets.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/vscode.png' : 'assets/images/tools/vscode.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise quotidiennement Teams, Word, et Outlook. J'ai les bases de PowerPoint et Excel.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/office.png' : 'assets/images/tools/office.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise énormément Notion pour des prises de notes puis je crée des pages pour chaque projet avec toutes les informations de celui-ci.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/notion.png' : 'assets/images/tools/notion.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise Photoshop quotidiennement pour des retouches ou créations d'images.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/photoshop.png' : 'assets/images/tools/photoshop.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item"  title="J'utilise termius pour me connecter en SSH et en SFTP.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/termius.png' : 'assets/images/tools/termius.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6" style='margin-bottom: 65px'>
      <!-- client item -->
      <div class="client-item" title="J'utilise yarn à la place de npm pour la gestion de mes paquets JavaScript.">
        <div class="inner">
          <img [src]="themeType === 'dark' ? 'assets/images/tools/yarn.png' : 'assets/images/tools/yarn.png'" alt="client-name" />
        </div>
      </div>
    </div>
  </div>
</div>
