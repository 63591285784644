<div class="container">
  <h2 class="section-title wow fadeInUp">Contactez-moi !</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-4">
      <div class="contact-info">
        <h3 class="wow fadeInUp">Des questions ?</h3>
        <p class="wow fadeInUp">N'hésitez pas à me contacter pour toute question ! 👋</p>
      </div>

      <div class="alert alert-success alert-mail-sending" role="alert" *ngIf='mailSendSuccess'>
        Le mail a été envoyé avec succès !
      </div>

      <div class="alert alert-danger alert-mail-sending" role="alert" *ngIf='mailSendError'>
        Une erreur a eu lieu, le mail n'a pas été envoyé !
      </div>
    </div>

    <div class="col-md-8">
      <form id="contact-form" class="contact-form mt-6" method="post">
        <div class="messages"></div>

        <div class="row">
          <div class="column col-md-6">
            <div class="form-group">
              <input
                type="text"
                name="nom"
                class="form-control"
                placeholder="Votre nom"
                required="required"
                data-error="Votre nom est obligatoire !"
                (keyup)='checkValidityForm()'
                [(ngModel)]='formulaireContact.nom'
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <div class="form-group">
              <input
                type="email"
                name="mail"
                class="form-control"
                placeholder="Votre email"
                required="required"
                data-error="Votre mail est obligatoire !"
                (keyup)='checkValidityForm()'
                [(ngModel)]='formulaireContact.mail'
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <div class="form-group">
              <input
                type="text"
                name="sujet"
                class="form-control"
                placeholder="Sujet"
                required="required"
                data-error="Le sujet est obligatoire !"
                (keyup)='checkValidityForm()'
                [(ngModel)]='formulaireContact.sujet'
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <div class="form-group">
              <textarea
                rows="5"
                name="message"
                class="form-control"
                placeholder="Message"
                required="required"
                data-error="Le message est obligatoire !"
                (keyup)='checkValidityForm()'
                [(ngModel)]='formulaireContact.message'
              ></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>
        <button type="submit" [ngClass]="formulaireValid ? 'btn btn-default' : 'btn disabled-submit-btn'"
                [disabled]='!formulaireValid'
                (click)='sendContactMail()'>
          Envoyer le message !
        </button>
      </form>
    </div>
  </div>
</div>
