import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Work } from '../Interfaces/work';

@Injectable({
  providedIn: 'root'
})
export class WorkService {
  urlApi: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllWorks(): Observable<Work[]> {
    return new Observable((observer: Subscriber<Work[]>) => {
      this.http.get<Work[]>(this.urlApi + '/work/get-all')
        .subscribe((ResultWorks: Work[]) =>
          observer.next(ResultWorks));
    });
  }
}
